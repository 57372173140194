.screen {
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 30px;
}

.app-logo {
  width: 40px;
  height: 40px;
}

.logo-buffer {
  height: 8vmax;
  min-height: 40px;
  max-height: 80px;
}

.sm-logo-buffer {
  height: 6vmax;
  min-height: 20px;
  max-height: 40px;
}

@font-face {
  font-family: 'Steppe';
  font-style: normal;
  font-weight: 400;
  src: local('Steppe'), url(./assets/fonts/Brandon_reg.otf) 
}

@font-face {
  font-family: 'Steppe';
  font-weight: 500;
  src: local('Steppe'), url(./assets/fonts/Brandon_med.otf) 
}

@font-face {
  font-family: 'Steppe';
  font-weight: 600;
  src: local('Steppe'), url(./assets/fonts/Brandon_bld.otf) 
}


/* Text Colors */

.white-text {
  color: #FFFFFF;
}

.gray-text {
  color: #96979C;
}

h2, p,
.dark-gray-text {
  color: #2F2F2F;
}

.dark-blue-text {
  color: #21365A;
}


/* Background Colors */

.blue-bg {
  background-color: #3462A3;
}

.light-gray-bg {
  background-color: #F3F3F3;
}


/* Hover Colors */

.blue-hover:hover {
  background-color: rgb(79, 120, 179);
}

input[type="text"],
textarea,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

textarea,
input[type="text"] {
  width: 94%;
  height: 20px;
  margin: 5px 0;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  padding: 5px 10px;
  overflow: scroll;
  vertical-align: top;
}

textarea:focus,
input[type="text"]:focus {
  border: 1px solid #d8d8d8;
  outline: none;
}

textarea {
  resize: none;
  font-style: unset;
}

button {
  cursor: pointer;
  margin: 10px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: #3462A3;
  border: none;
  border-radius: 5px;
  color: white;
}

button:hover {
  background-color: rgb(79, 120, 179);
}

button:disabled {
  background-color: #96979C;
}