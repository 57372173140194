.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.launch-text {
  font-size: calc(1.16vw + 1rem);
  font-weight: 300;
}

.app-name {
  font-family: 'Steppe';
  font-size: calc(4vw + 3rem);
  font-weight: 600;
  line-height: calc(5.5vw + 3rem);
}

.app-tag {
  font-size: calc(1.16vw + 1rem);
  font-weight: 300;
}

.download-buffer {
  height: 6vmax;
  min-height: 35px;
  max-height: 55px;
}

.download-button {
  cursor: pointer;
  height: 60px;
  width: 160px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 300;
}

.base-section {
  min-height: 82vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.phone-image {
  height: 75vh;
  object-fit: cover;
}